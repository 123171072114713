import '../styles/Footer.css'

const Footer = () => {
    return (
        <footer className="custom-shape-divider-bottom-1712580221">

            <div className="custom-shape-divider-bottom-1712580690">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>

                </svg>
            </div>
        </footer>
    );
}

export default Footer;