import Card from './components/Card'
import './styles/Contact.css'

const Contact = () => {
    return (
        <div className='container-contact'>
            <div className='wrapper-contact'>
                <Card />
            </div>

        </div>
    );
}

export default Contact;